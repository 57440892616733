import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import { ThemeProvider } from "styled-components"
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles"
import theme from "../styles/theme/index.js"
import GlobalCss from "../styles/theme/global"
import Wrapper from "../components/layout/Wrapper"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import SEO from "../components/SEO"

export default function fourOhFour({ data }) {
  const metadata = {
    seoTitle: "Page not found",
    seoDescription: { seoDescription: "404 Page not found – Expend" },
    seoImage:
      data.allContentfulWebsiteMap.edges[0].node.homepage.metadata.seoImage,
  }

  return (
    <StylesProvider>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <GlobalCss />
          <Layout hasHero={false}>
            <SEO data={metadata} canonicalUrl="https://expend.com/404" />
            <Wrapper>
              <Body>
                <Typography color="textPrimary" variant="h1">
                  Oops!
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  We can't seem to find the page you're looking for.
                </Typography>
                <Typography color="textPrimary" variant="body1">
                  Error code: 404
                </Typography>
              </Body>
            </Wrapper>
          </Layout>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  )
}

const Body = styled(Box)`
  ${({ theme }) => `
        padding-top: ${theme.spacing(8)}px;
        min-height: 50vh;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        & > h1, & > h3 {
            margin-bottom: ${theme.spacing(2)}px;
        }
    `}
`

export const query = graphql`
  query {
    allContentfulWebsiteMap {
      edges {
        node {
          homepage {
            metadata {
              seoImage {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
